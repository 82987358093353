import { createTheme } from '@mui/material/styles';
export const GigXrTheme = createTheme({
  palette: {
    primary: { main: '#8B8BC3' },
  },
  typography: {
    fontFamily: "'Lato', sans-serif;",
    caption: {
      fontFamily: "'Montserrat', sans-serif;",
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#31373D',
          color: '#FFFFFF',
          width: 283,
        },
        root: {
          '& .MuiTypography-root': {
            fontSize: '21px',
          },
        },
      },
    },
    // MuiListItemText: {
    //   styleOverrides: {
    //     root: {
    //       fontSize: '21px',
    //     },
    //   },
    // },
    MuiFilledInput: {
      styleOverrides: {
        input: {
          ':-internal-autofill-selected': {
            backgroundColor: 'red',
          },
          // '&:-webkit-autofill': {
          //   WebkitBoxShadow: '0 0 0 1000px white inset',
          // },
        },
      },
      // input: {
      //     '&:-webkit-autofill': {
      //       '-webkit-box-shadow': '0 0 0 100px var(--primary-weak) inset',
      //       '-webkit-text-fill-color': 'var(--text-primary)',
      //     },
      //   },
      // },
    },
  },
  // overrides: {
  //   MuiCssBaseline: {
  //     '@global': {
  //       '*': {
  //         margin: 0,
  //         padding: 0,
  //       },
  //       'html, body, #root': {
  //         height: '100%',
  //       },
  //       ul: {
  //         listStyle: 'none',
  //       },
  //     },
  //   },
  //   MuiSvgIcon: {
  //     root: { verticalAlign: 'middle' },
  //   },
  // },
});
