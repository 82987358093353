import React from 'react';
import Box from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useMsal } from '@azure/msal-react';
import RowLesson from '../components/RowLesson';
import SessionImg1 from '../img/sessions-1-528-248.jpg';
import LessonImg from '../img/lesson-528-248.jpg';
import MusclesAndMovementImg from '../img/muscles-and-movement.jpg';
import tray from '../img/tray.jpg';
import LinkButton from '../components/LinkButton';
import { LessonListType } from '../helpers/types';

const SectionTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 700,
  color: '#4A4A4A',
  marginTop: '50px',
}));

const assignedLesson: LessonListType = {
  title: 'Asthma Lesson',
  summary:
    'This lesson will review an airway condition in which a patients airway becomes inflamed, narrow, swollen, and causes excess mucus causing difficulties breathing.  The lesson will guide you through readings, videos, XR experience, and a debrief section.',
  image: LessonImg,
};

const iVCannulation: LessonListType = {
  title: 'Peripheral IV Cannulation',
  summary:
    'This lesson will introduce how to properly select, insert and care for a peripheral IV. The lesson includes pre-procedure discussion with the patient, an introduction to the anatomy of the human are, and an immersive simulation covering each step of the peripheral IV cannulation procedure. Each of these specific areas will be assessed for discussion during debriefing.',
  image: tray,
};

const aboutLesson: Array<LessonListType> = [{
  title: 'Anaphylaxis',
  summary:
    'This lesson will review a severe and potentially life-threatening reaction to a trigger such as an allergy. The lesson will guide you through readings, videos, XR experience, and a debrief section.',
  image: SessionImg1,
  starting: '9/18/2022 01:00pm',
},
{
  title: 'Asthma Lesson',
  summary:
    'This lesson will review an airway condition in which a patients airway becomes inflamed, narrow, swollen, and causes excess mucus causing difficulties breathing.  The lesson will guide you through readings, videos, XR experience, and a debrief section.',
  image: LessonImg,
  starting: '9/18/2022 01:00pm'
},
{
  title: 'Muscles & Movement',
  summary:
    'Anatomy lesson shared by Dr. Connie Scanga (Penn Nursing).',
  image: MusclesAndMovementImg,
  starting: 'August 24, 2023'
}];

function Dashboard(): JSX.Element {
  const { accounts } = useMsal();
  // const [user, setUser] = useState(initState);

  return (
    <Box>
      <Typography variant="h1" component="h2" sx={{ fontSize: '32px', fontWeight: 'bold', letterSpacing: '0.1rem' }}>
        Welcome Back, {accounts.length > 0 ? accounts[0].name : ''}
      </Typography>
      <SectionTitle>Assigned Lessons</SectionTitle>
            <Box sx={{ marginTop: '57px' }}>
        <RowLesson data={iVCannulation}>
          <LinkButton to={'/app/PeripheralIVCannulation'} title="Go to lesson" /> 
        </RowLesson>
      </Box>
      {/* <Box sx={{ marginTop: '57px' }}>
        <RowLesson data={assignedLesson}>
          <LinkButton to={'/app/lesson'} title="Go to lesson" />
        </RowLesson>
      </Box> */}
      <SectionTitle sx={{ marginBottom: '47px', marginTop: '90px' }}>Upcoming Lessons</SectionTitle>
      <RowLesson data={aboutLesson.find(item => item.title == 'Asthma Lesson')}>
        <LinkButton to={''} disabled title="Starts in 3 days" />
      </RowLesson>
      <RowLesson data={aboutLesson.find(item => item.title == 'Muscles & Movement')}>
        <LinkButton to={''} disabled title="Starts in 3 days" />
      </RowLesson>
      <RowLesson data={aboutLesson.find(item => item.title == 'Anaphylaxis')}>
        <LinkButton to={''} disabled title="Starts in 3 days" />
      </RowLesson>
    </Box>
  );
}

export default Dashboard;
