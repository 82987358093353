import * as React from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Chip } from '@mui/material';
import AvatarG from '../img/avatar-g.png';
import { styled } from '@mui/material/styles';

const CustomChip = styled(Chip)<TypographyProps>(({ theme }) => ({
  ...theme.typography.caption,
  fontWeight: 400,
  color: '#000000',
  letterSpacing: '0.01em',
  borderColor: '#C4C4C4',
  fontSize: '16px',
  padding: '10px',
}));

const LessonTop: React.FC = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'col', alignItems: 'center' }}>
      <Box sx={{ width: '44px', height: '44px', marginRight: '27px' }}>
        <img src={AvatarG} alt={'Asthma'} />
      </Box>
      <Typography sx={{ color: '#4A4A4A', fontWeight: 700, fontSize: '32px', marginRight: '79px' }}>Asthma</Typography>
      <Typography sx={{ color: '#4A4A4A', fontWeight: 700, fontSize: '20px', marginRight: '12px' }}>
        Assigned Class:
      </Typography>
      <CustomChip label="Med-Surg 101" variant="outlined" />
    </Box>
  );
};

export default LessonTop;
