import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function isCompleted(value: boolean) {
  if (value !== null && value !== undefined && value === true) {
    return <CheckCircleIcon sx={{ color: '#62BEFC' }} />
  } else if(value !== null && value !== undefined && value === false){
    return <CheckCircleIcon sx={{ color: '#EFEFEF' }} />
  }
}

export default function ProgressTable(props: any) {
  return (
    <TableContainer component={Paper} sx={{ marginLeft: '30px', borderRadius: '1px'}}>
      <Table sx={{ minWidth: 600, maxHeight: '100px', borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC", borderRight: "1px solid #62BEFC", borderLeft: "1px solid #62BEFC"}} aria-label="simple table" color="blue">
        <TableHead>
          <TableRow>
            <TableCell sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC" }}>Name</TableCell>
            <TableCell sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC" }}>1</TableCell>
            <TableCell sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC" }}>2</TableCell>
            <TableCell sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC" }}>3</TableCell>
            <TableCell sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC" }}>4</TableCell>
            <TableCell sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC" }}>5</TableCell>
            <TableCell align="right" sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC" }}>Procedure Complete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableRows.map((item) => (
              <TableRow
              key={item.name1}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
            >
              <TableCell component="th" scope="row" sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC" }}>
                {item.name1}
              </TableCell>
              <TableCell component="th" scope="row" sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC" }}>
                {isCompleted(item.objectives1[0])}
              </TableCell>
              <TableCell component="th" scope="row" sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC" }}>
              {isCompleted(item.objectives1[1])}
              </TableCell>
              <TableCell component="th" scope="row" sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC" }}>
              {isCompleted(item.objectives1[2])}
              </TableCell>
              <TableCell component="th" scope="row" sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC" }}>
              {isCompleted(item.objectives1[3])}
              </TableCell>
              <TableCell component="th" scope="row" sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC" }}>
              {isCompleted(item.objectives1[4])}
              </TableCell>
              <TableCell align="right" sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC" }}>
              {isCompleted(false)}
              </TableCell>
            </TableRow>
            ))}
            {props.tableRows.map((item) => (
              <TableRow
              key={item.name2}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
            >
              <TableCell component="th" scope="row" sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC" }}>
                {"Stephen McIver"}
              </TableCell>
              <TableCell component="th" scope="row" sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC" }}>
                {isCompleted(true)}
              </TableCell>
              <TableCell component="th" scope="row" sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC" }}>
              {isCompleted(item.objectives2[1])}
              </TableCell>
              <TableCell component="th" scope="row" sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC" }}>
              {isCompleted(item.objectives2[2])}
              </TableCell>
              <TableCell component="th" scope="row" sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC" }}>
              {isCompleted(item.objectives2[3])}
              </TableCell>
              <TableCell component="th" scope="row" sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC" }}>
              {isCompleted(item.objectives2[4])}
              </TableCell>
              <TableCell align="right" sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC" }}>
              {isCompleted(false)}
              </TableCell>
            </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}