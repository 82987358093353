import React, { useContext, useEffect, useRef, useState } from 'react';
import { useAuthentication } from '../services/security';
import { Link as RouterLink, Outlet, useNavigate, useLocation, useMatch } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import SidebarMenu from './SidebarMenu';
import Button, { ButtonProps } from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Paper, Stack } from '@mui/material';
let VALogo = require('../img/VA-logo.png');
let GigXRLogo = require('../img/logo-gigxr-sm.png');
import { ReactComponent as LogoVA } from '../img/GigXR+VA Logo top bar_V2.svg';
import { AppContext } from './AppContext';

const drawerWidth: number = 283;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer - 1,
  '& .MuiToolbar-root': {
    marginLeft: !open ? '72px' : 0,
  },
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const LogoutButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: '#ffffff',
  color: '#000000',
  borderColor: '#000000',
  '&:hover': {
    backgroundColor: '#ffffff',
    borderColor: '#000000',
  },
}));

const Logo = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  boxShadow: 'none',
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const Layout: React.FC = () => {
  const appContext = useContext(AppContext);
  const [open, setOpen] = React.useState(false);
  const [showBreadcrumb, setShowBreadcrumb] = useState(false);
  let { auth } = useAuthentication();
  let navigate = useNavigate();
  let location = useLocation();
  const myRef = useRef(null);

  //TODO add dynamically breadcrumbs
  const homeMatches = useMatch('/app/');
  const lessonMatches = useMatch('/app/lesson');
  const historyMatches = useMatch('/app/history');
  const sessionMatches = useMatch('/app/session/:id');

  // useEffect(() => {
  //   if (auth.user === null && auth.reqStatus === REQ_STATUS_RESOLVED) {
  //     navigate('/');
  //   }
  // }, [auth.user, auth.reqStatus]);

  useEffect(() => {
    //TODO fix the API  multiple requests
    const interval = setInterval(() => {
      getAvailableSessions();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    myRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [location.pathname]);

  useEffect(() => {
    return () => {
      setOpen(false);
      setShowBreadcrumb(false);
    };
  }, []);

  useEffect(() => {
    const path = location.pathname.replace(/\/app\//g, '');
    setShowBreadcrumb(path !== '');
  }, [location]);

  const getAvailableSessions = async () => {
    if (appContext.jwt !== '') {
      try {
        const response = await fetch(`https://gms-qa.gigxr.com/api/v1/sessions/available`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-auth-gms': 'Bearer ' + appContext.jwt,
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const logOut = () => {
    navigate('/');
    // auth.signOut();
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="absolute" open={open} elevation={0} sx={{ backgroundColor: '#EFF2F5' }}>
        <Toolbar sx={{ pr: '24px', height: '76px', justifyContent: 'space-between' }}>
          <Stack sx={{ height: '51px', marginLeft: '20px' }} direction="row" spacing={{ sm: 7 }} alignItems="center">
            <Logo sx={{ display: 'inline-flex' }}>
              <LogoVA height="51" />
            </Logo>
          </Stack>
          <LogoutButton variant="outlined" onClick={() => logOut()}>
            Log Out
          </LogoutButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Divider />
        <List component="nav" sx={{ mt: 15 }}>
          <SidebarMenu handleToggleDrawer={toggleDrawer} />
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: '#ffffff',
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Box
          onClick={toggleDrawer}
          sx={{
            position: 'absolute',
            height: '100vh',
            backgroundColor: 'rgba(49, 55, 61, 0.5)',
            zIndex: 9999,
            ...(!open && { display: 'none', width: '100%' }),
            ...(open && { width: `calc(100% - ${drawerWidth}px)` }),
          }}
        />
        <Toolbar ref={myRef} />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container>
            {showBreadcrumb && (
              <Grid item xs={12} sx={{ marginBottom: '40px' }}>
                <Breadcrumbs aria-label="breadcrumb" separator={'>'}>
                  <Link
                    underline="hover"
                    to={'/app/'}
                    component={RouterLink}
                    sx={{ fontSize: '14px', color: '#4A4A4A' }}
                  >
                    Dashboard
                  </Link>
                  {lessonMatches !== null && (
                    <Typography sx={{ fontSize: '14px', color: '#4A4A4A' }}>Asthma - Med-Surg 101</Typography>
                  )}
                  {(historyMatches !== null || sessionMatches !== null) && (
                    <Link
                      underline="hover"
                      to={'/app/lesson'}
                      component={RouterLink}
                      sx={{ fontSize: '14px', color: '#4A4A4A' }}
                    >
                      Asthma - Med-Surg 101
                    </Link>
                  )}
                  {historyMatches !== null && (
                    <Typography sx={{ fontSize: '14px', color: '#4A4A4A' }}>XR Session History</Typography>
                  )}
                  {sessionMatches !== null && (
                    <Link
                      underline="hover"
                      to={'/app/history'}
                      component={RouterLink}
                      sx={{ fontSize: '14px', color: '#4A4A4A' }}
                    >
                      XR Session History
                    </Link>
                  )}
                  {sessionMatches !== null && (
                    <Typography sx={{ fontSize: '14px', color: '#4A4A4A' }}> Session Details </Typography>
                  )}
                </Breadcrumbs>
              </Grid>
            )}
            <Grid item xs={12} sx={{ marginBottom: '100px' }}>
              <Outlet />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Layout;
