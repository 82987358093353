export const REQ_STATUS_IDLE = 'idle';
export const REQ_STATUS_REJECTED = 'rejected';
export const REQ_STATUS_RESOLVED = 'resolved';
export const REQ_STATUS_PENDING = 'pending';
export declare type RequestStatusType = 'idle' | 'pending' | 'resolved' | 'rejected';

export type UserType = {
  accountId: string;
  firstName: string;
  lastName: string;
  email: string;
  accountRole: string;
};
export type JWTLoginDecodeType = {
  nameid: string;
  role: string;
  unique_name: string;
  firstName: string;
  lastName: string;
  exp: number;
  nbf: number;
  iat: number;
  jti: string;
};
export type LessonListType = {
  title: string;
  summary: string;
  image: string;
  starting?: string;
};
