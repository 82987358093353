import React, { useState } from 'react';
import { UserType } from '../helpers/types';

export type AppContextType = {
  user: UserType | null;
  jwt: string;
  setJwt: (d: string) => void;
  setUser: (u: UserType) => void;
};
export const AppContext = React.createContext<Partial<AppContextType>>({
  jwt: '',
});

type LayoutPropsType = {
  children: React.ReactNode;
};
export const AppProvider = ({ children }: LayoutPropsType) => {
  const [jwt, setJwt] = useState<string>('');
  const [user, setUser] = useState<UserType | null>(null);

  return (
    <AppContext.Provider
      value={{
        jwt,
        setJwt,
        user,
        setUser,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
