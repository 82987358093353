import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useAuthentication } from '../services/security';
import { ReactComponent as GIGXRLogo } from '../img/logo-dark.svg';
import { ReactComponent as IconUser } from '../img/icon-user.svg';
import { ReactComponent as IconLock } from '../img/icon-lock.svg';
import VALogo from '../img/VA Logo_V2.svg';
import Divider from '@mui/material/Divider';
import { Alert, FilledInput, FormControl, InputAdornment, InputLabel } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { REQ_STATUS_RESOLVED } from '../helpers/types';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../helpers/authConfig';

export type SignInType = {
  email: string;
  password: string;
};
function CustomLabel() {
  return (
    <>
      <Typography sx={{ fontSize: '23px', lineHeight: 1, fontWeight: 500 }}>Keep me logged in </Typography>
      <Typography sx={{ fontSize: '20px', mt: '5px', fontWeight: 500 }}>
        Do not select if this is a shared device
      </Typography>
    </>
  );
}
const StyledFilledInput = styled(FilledInput)`
  & input:-internal-autofill-selected {
    background-color: red !important;
  }
`;

export default function SignIn() {
  const { auth } = useAuthentication();
  const { instance } = useMsal();

  let navigate = useNavigate();
  const [showError, setShowError] = useState<boolean>(false);
  let locationFrom = location.state?.from?.pathname || '/app/';

  const handleMsftLogin = () => {
    instance.loginPopup(loginRequest).catch((e) => {
      console.log(e);
    });
  };

  const {
    handleSubmit,
    control,
    formState: {},
  } = useForm<SignInType>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  useEffect(() => {
    return () => {
      setShowError(false);
    };
  }, []);

  useEffect(() => {
    if (auth.user !== null) {
      navigate(locationFrom, { replace: true });
    }
    if (auth.user === null && auth.reqStatus === REQ_STATUS_RESOLVED) {
      setShowError(true);
    }
  }, [auth.user, auth.reqStatus]);

  const onSubmit = (formData: SignInType): void => {
    auth.signIn(formData);
    handleMsftLogin();
  };

  return (
    <Container component="main" sx={{ width: '530px' }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <GIGXRLogo sx={{ width: '167px' }} />
        <Box sx={{ marginTop: '60px', marginBottom: '50px', paddingLeft: '25px' }}>
          <img src={VALogo} alt="VA logo" />
        </Box>
        <Typography component="h1" variant="h5" sx={{ fontSize: '33px', lineHeight: '40px', color: '#000000' }}>
          Sign in to enter.
        </Typography>
        <Divider sx={{ width: '52px', height: '2px', color: '#94AABB', marginY: '50px' }} />
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 0 }}>
          <Alert severity="error" sx={{ display: showError ? 'block' : 'none' }} icon={false}>
            This is an error alert — check it out!
          </Alert>
          <Controller
            name="email"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
              <>
                <FormControl
                  fullWidth
                  variant="filled"
                  sx={{
                    borderTopLeftRadius: '15px',
                    borderTopRightRadius: '15px',
                    backgroundColor: 'rgba(148, 170, 187, 0.15)',
                  }}
                >
                  <InputLabel htmlFor="filled-adornment-password" sx={{ marginLeft: '40px', color: '#94AABB' }}>
                    Email
                  </InputLabel>
                  <FilledInput
                    id="filled-adornment-email"
                    type="text"
                    onChange={onChange}
                    value={value === null ? '' : value}
                    sx={{
                      paddingX: '24px',
                      paddingBottom: '15px',
                      borderTopLeftRadius: '15px',
                      borderTopRightRadius: '15px',
                    }}
                    startAdornment={
                      <InputAdornment position="start" sx={{ width: '24px', height: '24px', margin: 0 }}>
                        <IconUser />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </>
            )}
          />

          <Controller
            name="password"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
              <>
                <FormControl
                  fullWidth
                  variant="filled"
                  sx={{
                    borderTopLeftRadius: '15px',
                    borderTopRightRadius: '15px',
                    backgroundColor: 'rgba(148, 170, 187, 0.15)',
                    marginTop: '30px',
                  }}
                >
                  <InputLabel htmlFor="filled-adornment-password" sx={{ marginLeft: '40px', color: '#94AABB' }}>
                    Password
                  </InputLabel>
                  <FilledInput
                    id="filled-adornment-password"
                    type="password"
                    onChange={onChange}
                    value={value === null ? '' : value}
                    sx={{
                      paddingX: '24px',
                      paddingBottom: '15px',
                      borderTopLeftRadius: '15px',
                      borderTopRightRadius: '15px',
                    }}
                    startAdornment={
                      <InputAdornment position="start" sx={{ width: '24px', height: '24px', margin: 0 }}>
                        <IconLock />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </>
            )}
          />

          <FormControlLabel
            sx={{ marginX: 'auto', width: '385px', justifySelf: 'center', display: 'flex', marginY: '30px' }}
            control={<Checkbox value="remember" color="primary" />}
            label={<CustomLabel />}
          />
          <Button
            type="submit"
            disableElevation
            fullWidth
            variant="contained"
            sx={{ mt: 0, fontSize: '20px', fontWeight: 700 }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
