import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  Paper,
  TableBody,
  TableCell,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Icon,
  Stack,
  Alert,
  CircularProgress,
} from '@mui/material';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ReactComponent as IconCheck } from '../img/icon-check.svg';
import sessionDetails from '../local-data/sessionDetails.json';
import { useParams } from 'react-router-dom';
import sessions from '../local-data/sessions.json';

const PageTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '24px',
  lineHeight: '29px',
  letterSpacing: '0.1em',
}));
const Item = styled(Paper)(({ theme }) => ({
  width: '204px',
  height: '110px',
  backgroundColor: '#ffffff',
  borderRadius: '5px',
  border: 'solid 1px #A6A6A6',
  padding: '21px 10px 0 10px',
  boxShadow: 'none',
  textAlign: 'center',
}));
const ItemTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  color: '#000000',
  fontSize: '32px',
  fontWeight: 700,
  lineHeight: '39px',
}));
const ItemText = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  color: '#000000',
  fontSize: '15px',
  fontWeight: 400,
  lineHeight: '18px',
  marginTop: '10px',
}));
const SectionTitle = styled(Typography)(({ theme }) => ({
  color: '#000000',
  fontSize: '24px',
  fontWeight: 500,
  lineHeight: '29px',
  marginTop: '60px',
}));
const TCellBordered = styled(TableCell)(({ theme }) => ({
  border: 'solid 1px #62BEFC',
}));
const TContainer = styled(TableContainer)(({ theme }) => ({
  boxShadow: 'none',
  borderRadius: 0,
  '& .MuiTableCell-root': {
    fontFamily: 'Montserrat',
    fontSize: '15px',
    lineHeight: '18px',
    fontWeight: 400,
    color: '#000000',
  },
}));
const BoxIconCheck = styled(Box)(({ theme }) => ({
  width: '19px',
  display: 'inline-flex',
}));
const SessionDetails: React.FC = () => {
  let { id } = useParams();
  const [session, setSession] = useState<any>(null);

  useEffect(() => {
    if (sessions !== undefined) {
      const data = sessions.find((item, index) => {
        return index === parseInt(id, 10);
      });
      setSession(data);
    }
  }, []);

  if (session === null) {
    return <CircularProgress color="secondary" />;
  }

  if (session === undefined) {
    return <Alert color={'error'}>No session found</Alert>;
  }

  return (
    <Box>
      <PageTitle variant="h1">{session.title}</PageTitle>
      <Stack direction="row" spacing={5} sx={{ marginTop: '62px' }}>
        <Item>
          <ItemTitle>{session.details.noOfStudents}</ItemTitle>
          <ItemText>Students</ItemText>
        </Item>
        <Item>
          <IconCheck sx={{ width: '38px', height: '38px' }} />
          <ItemText>{session.details.completed ? 'Session Completed' : 'Session Incomplete'}</ItemText>
        </Item>
        <Item>
          <ItemTitle>{session.details.timeInScenario}</ItemTitle>
          <ItemText>Time in Scenario</ItemText>
        </Item>
        <Item>
          <ItemTitle>{session.details.scenarioPassRate}</ItemTitle>
          <ItemText>Scenario Pass Rate</ItemText>
        </Item>
      </Stack>
      <SectionTitle>Session Details</SectionTitle>
      <Typography
        sx={{
          fontSize: '20px',
          lineHeight: '34px',
          fontFamily: 'Montserrat',
          marginTop: '15px',
          color: '#000000',
        }}
      >
        Session created: {session.details.sessionCreated}
        <br />
        Session Started: {session.details.sessionStarted}
        <br />
        Session Ended: {session.details.sessionEnded}
        <br />
        Users: {session.details.users}
      </Typography>
      <SectionTitle>Assessed Tasks</SectionTitle>
      <Typography
        sx={{
          fontSize: '15px',
          lineHeight: '18px',
          fontFamily: 'Montserrat',
          marginTop: '20px',
          color: '#000000',
        }}
      >
        Summary of results based on the assessment selected above.
      </Typography>
      <TContainer component={Paper} sx={{ marginTop: '49px' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TCellBordered sx={{ width: '250px' }} />
              <TCellBordered sx={{ paddingLeft: '150px' }}>Description</TCellBordered>
              <TCellBordered align="center">Completed</TCellBordered>
            </TableRow>
            {session.assessedTasks.map((row) => (
              <TableRow key={row.title}>
                <TCellBordered component="th" scope="row">
                  {row.title}
                </TCellBordered>
                <TCellBordered>{row.description}</TCellBordered>
                <TCellBordered align="center">
                  <BoxIconCheck>
                    <IconCheck sx={{ width: '100%' }} />
                  </BoxIconCheck>
                </TCellBordered>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TContainer>

      <SectionTitle>Scenario Timeline and Tasks</SectionTitle>
      <TContainer component={Paper} sx={{ marginBottom: '60px', marginTop: '32px' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TCellBordered sx={{ width: '250px' }} />
              <TCellBordered sx={{ paddingLeft: '150px' }}>Description</TCellBordered>
              <TCellBordered align="center">Time Stamp</TCellBordered>
              <TCellBordered align="center">Task Achieved By:</TCellBordered>
            </TableRow>
            {session.scenarioTimelineTasks.map((row, key) => (
              <TableRow key={row.title + key}>
                <TCellBordered component="th" scope="row">
                  {row.title}
                </TCellBordered>
                <TCellBordered>{row.description}</TCellBordered>
                <TCellBordered align="center">{row.timeStamp}</TCellBordered>
                <TCellBordered align="center">{row.achievedBy}</TCellBordered>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TContainer>
    </Box>
  );
};

export default SessionDetails;
