import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import LessonTop from '../components/LessonTop';
import { Avatar, Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
let IconGlassesDark = require('../img/incon-glasses-dark.png');
import { styled } from '@mui/material/styles';
// import sessions from '../local-data/sessionHistory.json';
import sessions from '../local-data/sessions.json';

const StyledPaper = styled(Link)(({ theme }) => ({
  backgroundColor: '#EFF2F5',
  display: 'block',
  width: '100%',
  color: '#000000',
  borderRadius: '100px',
  padding: '18px 23px',
  boxShadow: 'none',
  marginTop: '8px',
}));

const SessionHistory: React.FC = () => {
  return (
    <Box>
      <LessonTop />
      <Box sx={{ marginTop: '43px' }}>
        {sessions.map((session, index) => {
          return (
            <StyledPaper component={RouterLink} to={`/app/session/${index}`} underline={'none'} key={index}>
              <Grid container wrap="nowrap">
                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar>
                    <img src={IconGlassesDark} />
                  </Avatar>
                </Grid>
                <Grid item xs sx={{ marginLeft: '46px' }}>
                  <Typography sx={{ fontSize: '20px', fontWeight: 300, lineHeight: '24px' }}>{session.user}</Typography>
                  <Typography sx={{ fontSize: '15px', fontWeight: 300, letterSpacing: '0.1em' }}>
                    {session.date}
                  </Typography>
                </Grid>
                <Grid item sx={{ marginRight: '25%', display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ fontSize: '15px', fontWeight: 300, letterSpacing: '0.1em' }}>
                    {session.isCompleted ? 'Completed' : 'Incomplete'}
                  </Typography>
                </Grid>
              </Grid>
            </StyledPaper>
          );
        })}
      </Box>
    </Box>
  );
};

export default SessionHistory;
