import React from 'react';

const Chatbot: React.FC = () => {
  return (
    <div
      style={{ height: '500px' }}
      dangerouslySetInnerHTML={{
        __html:
          "<iframe  src='https://web.powerva.microsoft.com/environments/Default-3878b10e-e048-4df9-8fcb-852bc21cb678/bots/new_bot_38c72c3b1dd245788188280c64d2ae23/webchat'         frameborder='0'         style='width: 100%; height: 100%;'      />",
      }}
    />
  );
};

export default Chatbot;
