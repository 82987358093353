import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const Btn = styled(Button)(({ theme }) => ({
  borderRadius: '0',
  boxShadow: 'none',
  padding: '20px 50px',
  color: '#ffffff',
  textTransform: 'none',
  fontSize: '16px',
  lineHeight: '19px',
  letterSpacing: '0.1em',
  display: 'inline-flex',
  margin: '0 auto 0 0 ',
  '&.Mui-disabled': {
    backgroundColor: '#E1E1F3',
    color: '#ffffff',
  },
}));

type PropType = {
  disabled?: boolean;
  title: string;
  to: string;
};

const LinkButton: React.FC<PropType> = ({ title, to, disabled }: PropType) => {
  return (
    <Btn variant="contained" color="primary" to={to} component={RouterLink} disabled={disabled}>
      {title}
    </Btn>
  );
};

export default LinkButton;
LinkButton.defaultProps = {
  disabled: false,
  title: '',
  to: '',
};
