import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Link as RouterLink } from 'react-router-dom';
import { Chip, Paper, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { ReactComponent as IconTeams } from '../img/icon-teams.svg';
import { ReactComponent as IconMSPolls } from '../img/icon-polls.svg';
import { ReactComponent as IconMSFeedback } from '../img/icon-feedback.svg';
import { ReactComponent as IconLessonBook } from '../img/icon-lesson-book.svg';
import { ReactComponent as IconLessonGlasses } from '../img/icon-lesson-eye-glasses.svg';
import { ReactComponent as IconLessonList } from '../img/icon-lesson-list.svg';
import { ReactComponent as IconArrow } from '../img/icon-arrow.svg';
import { ReactComponent as IconHistory } from '../img/icon-history.svg';
import { ReactComponent as IconFiles } from '../img/icon-files.svg';
import { ReactComponent as IconWhiteboard } from '../img/icon-whiteboard.svg';
import Tray from '../img/tray.jpg';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import LessonTop1 from '../components/LessonTop1';
import QRCodeGenerate from '../components/QRCodeGenerate';
import LessonDebrief from '../components/LessonDebrief';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import msLinks from '../local-data/msLinks.json';
import lessonLinks1 from '../local-data/lessonLinks1.json';
import Button from '@mui/material/Button';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    marginBottom: '30px',
    border: `1px solid #000000`,
    borderRadius: '10px',
    // '&:not(:last-child)': {
    //   borderBottom: 0,
    // },
    '&:before': {
      display: 'none',
    },
  }),
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<IconArrow sx={{ width: '15px', height: '30px' }} />} {...props} />
))(({ theme }) => ({
  // backgroundColor: '#ffffff',
  // borderRadius: '10px',
  backgroundColor: 'transparent',
  paddingLeft: '56px',
  paddingRight: '59px',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
    marginLeft: '32px',
    marginTop: '37px',
    marginBottom: '41px',
  },
  '.MuiTypography-root': {
    fontSize: '20px',
    color: '#000000',
    fontWeight: 300,
    marginLeft: '22px',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '30px',
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const IVCannulation: React.FC = () => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box>
      <LessonTop1 />
      <Box sx={{ marginTop: '75px' }}>
        <Card sx={{ display: 'flex', borderRadius: 'initial', boxShadow: 'unset' }}>
          <CardMedia
            component="img"
            sx={{ width: 528, height: 248, display: { xs: 'none', sm: 'block' } }}
            image={Tray}
            alt="About Lesson"
          />
          <CardContent
            sx={{
              flex: 1,
              marginLeft: '63px',
              p: '0 !important',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography
              component="h2"
              sx={{
                fontWeight: 500,
                fontSize: '24px',
                letterSpacing: '0.1em',
                color: '#000000',
              }}
            >
              About Lesson
            </Typography>
            <Typography sx={{ fontWeight: 300, letterSpacing: '0.1em', color: '#000000', my: '30px' }}>
              This lesson will introduce how to properly select, insert and care for a peripheral IV.
              The lesson includes pre-procedure discussion with the patient, an introduction to the anatomy of the human arm,
              and an immersive simulation covering each step of the peripheral IV cannulation procedure. Each of these specific areas will be assessed for discussion during debriefing.
            </Typography>
            <Typography sx={{ fontWeight: 500, lineHeight: '19px', letterSpacing: '0.1em', color: '#000000' }}>
              Learning Hours : 1 hour
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Stack direction="row" spacing={2} sx={{ marginTop: '35px' }}>
        <Link rel="noopener" target="_blank" href={msLinks.teams}>
          <IconTeams />
        </Link>
        <Link rel="noopener" target="_blank" href={msLinks.files}>
          <IconFiles />
        </Link>
        <Link rel="noopener" target="_blank" href={msLinks.whiteboard}>
          <IconWhiteboard />
        </Link>
        <Link rel="noopener" target="_blank" href={msLinks.polls}>
          <IconMSPolls />
        </Link>
        <Link rel="noopener" target="_blank" href={msLinks.feedback}>
          <IconMSFeedback />
        </Link>
      </Stack>

      <Box sx={{ marginTop: '40px' }}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <IconLessonBook />
            <Typography>Prebrief</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', flexDirection: 'column', paddingBottom: '10px' }}>
              <Box
                sx={{ marginBottom: '10px', width: '60%', height: '350px', position: 'relative' }}
                dangerouslySetInnerHTML={{
                  __html: lessonLinks1.video,
                }}
              />
              <Box
                sx={{ marginBottom: '10px', width: '60%', height: '350px', position: 'relative' }}
                dangerouslySetInnerHTML={{
                  __html: lessonLinks1.video1,
                }}
              />
              <Box
                sx={{ width: '60%', height: '350px', position: 'relative' }}
                dangerouslySetInnerHTML={{
                  __html: lessonLinks1.video2,
                }}
              />
            </Box>
            {lessonLinks1.preBriefingLinks.map((link) => {
              return (
                <Typography key={link.title}>
                  <Link rel="noopener" target="_blank" underline="none" href={link.url}>
                    {link.title}
                  </Link>
                </Typography>
              );
            })}
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" sx={{ position: 'relative' }}>
            <IconLessonGlasses />
            <Typography>Simulations</Typography>
            <Link
              component={RouterLink}
              to={'/app/history'}
              underline={'none'}
              sx={{
                position: 'absolute',
                right: '60px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
            </Link>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '20px', lineHeight: '18px', marginLeft: '10px', marginBottom: '50px' }}>
                Peripheral IV Consent
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '900', fontSize: '15px', lineHeight: '18px', marginLeft: '10px', marginBottom: '5px' }}>
                  Name of Patient:
                </Typography>
                <Typography sx={{ fontFamily: 'Montserrat', fontSize: '15px', lineHeight: '18px', marginLeft: '5px', marginBottom: '5px' }}>
                  Bianca Matthews
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '900', fontSize: '15px', lineHeight: '18px', marginLeft: '10px', marginBottom: '5px' }}>
                  App Name:
                </Typography>
                <Typography sx={{ fontFamily: 'Montserrat', fontSize: '15px', lineHeight: '18px', marginLeft: '5px', marginBottom: '20px' }}>
                  Conversations
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '15px',
                  lineHeight: '18px',
                  marginLeft: '10px',
                  marginRight: '20px',
                  marginBottom: '50px'
                }}
              >
                Learners will explain the peripheral iv cannulation procedure with a virtual patient and obtain their consent.
              </Typography>
              <Button variant="contained" color="primary" href='https://labs.gigxr.dev/GigXR' target="_blank"
                sx={{ textTransform: 'none', width: '160px', height: '48px', borderRadius: '0', marginLeft: '10px', marginBottom: '30px' }}>
                <Typography
                  sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '12px',
                    fontWeight: '400'
                  }}>
                  Launch app
                </Typography>
              </Button>
              <Box sx={{ background: 'black', marginBottom: '30px', paddingBottom: '1px' }}></Box>
              <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '20px', lineHeight: '18px', marginLeft: '10px', marginBottom: '50px' }}>
                Anatomy of a Peripheral IV
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '900', fontSize: '15px', lineHeight: '18px', marginLeft: '10px', marginBottom: '5px' }}>
                  App Name:
                </Typography>
                <Typography sx={{ fontFamily: 'Montserrat', fontSize: '15px', lineHeight: '18px', marginLeft: '5px', marginBottom: '20px' }}>
                  HoloHuman
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '15px',
                  lineHeight: '18px',
                  marginLeft: '10px',
                  marginRight: '20px',
                  marginBottom: '50px'
                }}
              >
                This exercise will assist learners in understanding the underlying anatomical structures that affect selection of IV insertion points.
              </Typography>
              <Box sx={{ marginBottom: '30px' }} >
                <QRCodeGenerate sessionName={"Anatomy of a Peripheral IV"}/>
              </Box>
              <Box sx={{ background: 'black', marginBottom: '30px', paddingBottom: '1px' }}></Box>
              <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '20px', lineHeight: '18px', marginLeft: '10px', marginBottom: '50px' }}>
                Peripheral IV Cannulation
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '900', fontSize: '15px', lineHeight: '18px', marginLeft: '10px', marginBottom: '5px' }}>
                  Name of Patient:
                </Typography>
                <Typography sx={{ fontFamily: 'Montserrat', fontSize: '15px', lineHeight: '18px', marginLeft: '5px', marginBottom: '5px' }}>
                  Bianca Matthews
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '900', fontSize: '15px', lineHeight: '18px', marginLeft: '10px', marginBottom: '5px' }}>
                  App Name:
                </Typography>
                <Typography sx={{ fontFamily: 'Montserrat', fontSize: '15px', lineHeight: '18px', marginLeft: '5px', marginBottom: '20px' }}>
                  HoloProcedures
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '15px',
                  lineHeight: '18px',
                  marginLeft: '10px',
                  marginRight: '20px',
                  marginBottom: '50px'
                }}
              >
                Learners will perform a peripheral IV cannulation using a holographic patient, equipment,
                and immersive guides. They will then practice what they learned on a physical task trainer with holographic guidance.
              </Typography>
              <Box sx={{ marginBottom: '15px' }} >
                <QRCodeGenerate sessionName={"Peripheral IV Cannulation"}/>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <IconLessonList />
            <Typography>Debrief</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <LessonDebrief />
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default IVCannulation;
