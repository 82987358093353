import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function ObjectivesTable1(props: any) {
  let index = 1;
  return (
    <TableContainer component={Paper} sx={{ marginLeft: '30px', borderRadius: '1px'}}>
      <Table sx={{ minWidth: 650, maxHeight: '100px', borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC", borderRight: "1px solid #62BEFC", borderLeft: "1px solid #62BEFC"}} aria-label="simple table" color="blue">
        <TableHead>
          <TableRow> 
            <TableCell sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC" }}></TableCell>
            <TableCell sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC" }}>Objectives</TableCell>
            <TableCell align="right" sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC", textAlign: 'left' }}>Goals</TableCell>
            <TableCell align="right" sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC", textAlign: 'left' }}>Completed</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableRows.table.map((item) => (
            <TableRow
              key={item.name}
            >
              <TableCell component="th" scope="row" sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC" }}>
                {index++}
              </TableCell>
              <TableCell component="th" scope="row" sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC" }}>
                {item.Objectives}
              </TableCell>
              <TableCell align="right" sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC", textAlign: 'left' }}>
                {item.Goals}
              </TableCell>
              <TableCell align="right" sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC", textAlign: 'left' }}>
                {item.Completed}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}