import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Link as RouterLink } from 'react-router-dom';
import { Chip, Paper, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { ReactComponent as IconTeams } from '../img/icon-teams.svg';
import { ReactComponent as IconMSPolls } from '../img/icon-polls.svg';
import { ReactComponent as IconMSFeedback } from '../img/icon-feedback.svg';
import { ReactComponent as IconLessonBook } from '../img/icon-lesson-book.svg';
import { ReactComponent as IconLessonGlasses } from '../img/icon-lesson-eye-glasses.svg';
import { ReactComponent as IconLessonList } from '../img/icon-lesson-list.svg';
import { ReactComponent as IconArrow } from '../img/icon-arrow.svg';
import { ReactComponent as IconHistory } from '../img/icon-history.svg';
import { ReactComponent as IconFiles } from '../img/icon-files.svg';
import { ReactComponent as IconWhiteboard } from '../img/icon-whiteboard.svg';
import LessonImg from '../img/lesson-528-248.jpg';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import LessonTop from '../components/LessonTop';
import QRCodeGenerate from '../components/QRCodeGenerate';
import LessonDebrief from '../components/LessonDebrief';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import msLinks from '../local-data/msLinks.json';
import lessonLinks from '../local-data/lessonLinks.json';
import preBriefingLinks from '../local-data/lessonLinks.json';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    marginBottom: '30px',
    border: `1px solid #000000`,
    borderRadius: '10px',
    // '&:not(:last-child)': {
    //   borderBottom: 0,
    // },
    '&:before': {
      display: 'none',
    },
  }),
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<IconArrow sx={{ width: '15px', height: '30px' }} />} {...props} />
))(({ theme }) => ({
  // backgroundColor: '#ffffff',
  // borderRadius: '10px',
  backgroundColor: 'transparent',
  paddingLeft: '56px',
  paddingRight: '59px',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
    marginLeft: '32px',
    marginTop: '37px',
    marginBottom: '41px',
  },
  '.MuiTypography-root': {
    fontSize: '20px',
    color: '#000000',
    fontWeight: 300,
    marginLeft: '22px',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '30px',
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Lesson: React.FC = () => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box>
      <LessonTop />
      <Box sx={{ marginTop: '75px' }}>
        <Card sx={{ display: 'flex', borderRadius: 'initial', boxShadow: 'unset' }}>
          <CardMedia
            component="img"
            sx={{ width: 528, height: 248, display: { xs: 'none', sm: 'block' } }}
            image={LessonImg}
            alt="About Lesson"
          />
          <CardContent
            sx={{
              flex: 1,
              marginLeft: '63px',
              p: '0 !important',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography
              component="h2"
              sx={{
                fontWeight: 500,
                fontSize: '24px',
                letterSpacing: '0.1em',
                color: '#000000',
              }}
            >
              About Lesson
            </Typography>
            <Typography sx={{ fontWeight: 300, letterSpacing: '0.1em', color: '#000000', my: '30px' }}>
              This lesson will review an airway condition in which a patients airway becomes inflamed, narrow, swollen,
              and causes excess mucus causing difficulties breathing. The lesson will guide you through readings,
              videos, XR experience, and a debrief section.
            </Typography>
            <Typography sx={{ fontWeight: 500, lineHeight: '19px', letterSpacing: '0.1em', color: '#000000' }}>
              Learning Hours : 1 hr
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Stack direction="row" spacing={2} sx={{ marginTop: '35px' }}>
        <Link rel="noopener" target="_blank" href={msLinks.teams}>
          <IconTeams />
        </Link>
        <Link rel="noopener" target="_blank" href={msLinks.files}>
          <IconFiles />
        </Link>
        <Link rel="noopener" target="_blank" href={msLinks.whiteboard}>
          <IconWhiteboard />
        </Link>
        <Link rel="noopener" target="_blank" href={msLinks.polls}>
          <IconMSPolls />
        </Link>
        <Link rel="noopener" target="_blank" href={msLinks.feedback}>
          <IconMSFeedback />
        </Link>
      </Stack>

      <Box sx={{ marginTop: '40px' }}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <IconLessonBook />
            <Typography>Lesson Pre-briefing</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{ padding: '75% 0 0 0', position: 'relative' }}
              dangerouslySetInnerHTML={{
                __html: lessonLinks.video,
              }}
            />

            {lessonLinks.preBriefingLinks.map((link) => {
              return (
                <Typography key={link.title}>
                  <Link rel="noopener" target="_blank" underline="none" href={link.url}>
                    {link.title}
                  </Link>
                </Typography>
              );
            })}
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" sx={{ position: 'relative' }}>
            <IconLessonGlasses />
            <Typography>Simulations </Typography>
            <Link
              component={RouterLink}
              to={'/app/history'}
              underline={'none'}
              sx={{
                position: 'absolute',
                right: '60px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box sx={{ width: '30px', height: '30px' }}>
                <IconHistory />
              </Box>
              <Box sx={{ fontSize: '12px', letterSpacing: '0.1em', marginLeft: '14px', color: '#000000' }}>
                XR Session History
              </Box>
            </Link>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', flexDirection: 'col' }}>
              <Box>
                <QRCodeGenerate />
              </Box>
              <Typography
                sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '15px',
                  lineHeight: '18px',
                  marginLeft: '70px',
                  marginRight: '20px',
                  marginTop: '30px',
                }}
              >
                When you open the app on Hololens, select the QR code log in, and point headset at QR code generated on
                the left.
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <IconLessonList />
            <Typography>Debrief</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontWeight: 300, letterSpacing: '0.1em' }}>
              Complete the questionnaire after you finished the XR session. For a detailed list of actions within the XR
              session, go to XR Session History and find the session you want to review. You can also view some
              information about your class performance in XR sessions in the Class Overview section.
            </Typography>
            <LessonDebrief />
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default Lesson;
