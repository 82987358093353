import React from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import SignIn from './pages/SignIn';
import Lesson from './pages/Lesson';
import IVCannulation from './pages/IVCannulation';
import Layout from './components/Layout';
import SessionHistory from './pages/SessionHistory';
import SessionDetails from './pages/SessionDetails';
import Chatbot from './pages/Chatbot';

const App: React.FC = () => {
  let routes: RouteObject[] = [
    {
      path: '/',
      element: <SignIn />,
    },
    {
      path: '/login',
      element: <SignIn />,
    },
    {
      path: '/app',
      element: <Layout />,
      children: [
        { index: true, element: <Dashboard /> },
        {
          path: '/app/lesson',
          element: <Lesson />,
        },
        {
          path: '/app/PeripheralIVCannulation',
          element: <IVCannulation />,
        },
        {
          path: '/app/history',
          element: <SessionHistory />,
        },
        {
          path: '/app/session/:id',
          element: <SessionDetails />,
        },
        {
          path: '/app/chat',
          element: <Chatbot />,
        },
      ],
    },
  ];
  return useRoutes(routes);
};

export default App;
