import * as React from 'react';
import Typography from '@mui/material/Typography';
import { ReactComponent as IconLessonList } from '../img/icon-lesson-list.svg';
import { ReactComponent as IconArrow } from '../img/icon-arrow.svg';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ClassOverview from './ClassOverview';
import Box from '@mui/material/Box';
import { ReactComponent as IconLessonGlasses } from '../img/icon-lesson-eye-glasses.svg';
import { ReactComponent as IconChatBox } from '../img/icon-chat-box.svg';
import Grid from '@mui/material/Grid';
import { Paper } from '@mui/material';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Padding } from '@mui/icons-material';
import ProgressTable from './ProgressTable';
import ProgressTable1 from './ProgressTable1';
import payload from '../local-data/payload.json';
import ObjectivesTable from './ObjectivesTable';
import ObjectivesTable1 from './ObjectivesTable1';
import ObjectivesData from '../local-data/ObjectivesData.json';
import TestTable from './TestTable';
import holographicPIVCObjectices from '../local-data/holographicPIVCObjectives.json';
import TaskTrainerPIVCObjectives from '../local-data/TaskTrainerPIVCObjectives.json';
import Button from '@mui/material/Button';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    marginBottom: '30px',
    borderBottom: `1px solid #000000`,
    '&:before': {
      display: 'none',
    },
  }),
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<IconArrow sx={{ width: '15px', height: '30px' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  paddingLeft: '70px',
  paddingRight: '59px',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
    marginLeft: '32px',
    marginTop: '37px',
    marginBottom: '41px',
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '30px',
  // borderTop: '1px solid rgba(0, 0, 0, .125)',
  // borderTop: '1px solid #000000',
}));

const TableCell = styled(Paper)(({ theme }) => ({
  borderRadius: '0px',
  border: 'solid 1px #ADD8E6',
  boxShadow: 'none',
  textAlign: 'left',
  height: '50px',
  paddingTop: '15px',
  paddingLeft: '30px',
}));

const ColumnTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  fontSize: '15px',
  fontWeight: 700,
  color: '#000000',
}));

const CellText = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  fontWeight: 450,
  fontSize: '14px',
  lineHeight: '18px',
}));

const TableContainer = styled(Grid)(({ theme }) => ({}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '5px',
  border: 'solid 1px #A6A6A6',
  paddingTop: '15px',
  boxShadow: 'none',
  textAlign: 'center',
  height: '95px',
  width: '180px'
}));

const BoxTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  fontSize: '30px',
  fontWeight: 700,
  lineHeight: '40px',
  color: '#000000',
}));

const BoxText = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '18px',
}));

const GridContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between'
}));

const QuizItem = styled(Paper)(({ theme }) => ({
  borderStyle: 'solid',
  borderWidth: '1px',
  borderRadius: '70px',
  height: '35px',
  width: '35px',
  textAlign: 'center',
  paddingTop: '4px'
}));

const date = new Date();
let day = date.getDate();
let year = date.getFullYear();

const names: Array<string> = [];

type tableRow = { name1: string, name2: string, objectives1: Array<boolean>, objectives2: Array<boolean>, isProcedureCompleted1: boolean, isProcedureCompleted2: boolean };

function serialize(prop: typeof payload.statements) {
  let row: tableRow = { name1: "", name2: "", objectives1: [], objectives2: [], isProcedureCompleted1: true, isProcedureCompleted2: true } || {};
  let table: tableRow[] = [] || [{}];
  prop.forEach((item) => {
    if (item.context.contextActivities.parent[0].id == 'https://va.gigxr.com/procedures/procedure-01') {
      if (item.actor.name == 'Chris Hartman') {
        names[0] = item.actor.name;
        row.name1 = item.actor.name
        row.objectives1[getObjectiveNumber(item.object.id)] = item.result!.completion;
        if (item.result!.completion == false) {
          row.isProcedureCompleted1 = false;
        }
      }
    } else if (item.context.contextActivities.parent[0].id == 'https://va.gigxr.com/procedures/procedure-02') {
      if (item.actor.name == 'Stephen McIver') {
        names[1] = item.actor.name;
        row.name2 = item.actor.name
        row.objectives2[getObjectiveNumber(item.object.id)] = item.result!.completion;
        if (item.result!.completion == false) {
          row.isProcedureCompleted2 = false;
        }
      }
    }
  });
  table.push(row);
  return table;
}

function serialize1(prop: typeof payload.statements) {
  let row: tableRow = { name1: "", name2: "", objectives1: [], objectives2: [], isProcedureCompleted1: true, isProcedureCompleted2: true } || {};
  let table: tableRow[] = [] || [{}];
  prop.forEach((item) => {
    if (item.context.contextActivities.parent[0].id == 'https://va.gigxr.com/procedures/procedure-03') {
      if (item.actor.name == 'Chris Hartman') {
        names[0] = item.actor.name;
        row.name1 = item.actor.name
        row.objectives1[getObjectiveNumber(item.object.id)] = item.result!.completion;
        if (item.result!.completion == false) {
          row.isProcedureCompleted1 = false;
        }
      }
    } else if (item.context.contextActivities.parent[0].id == 'https://va.gigxr.com/procedures/procedure-03') {
      if (item.actor.name == 'Stephen McIver') {
        names[1] = item.actor.name;
        row.name2 = item.actor.name
        row.objectives2[getObjectiveNumber(item.object.id)] = item.result!.completion;
        if (item.result!.completion == false) {
          row.isProcedureCompleted2 = false;
        }
      }
    }
  });
  table.push(row);
  return table;
}

type testRow = { actor: string, verb: string, objectName: string, json: string };

function serializeJsonTable(prop: typeof payload.statements) {
  let table: Array<testRow> = [];
  prop.forEach((item) => {
    table.push({
      actor: item.actor.name,
      verb: item.verb.display['en-US'],
      objectName: item.object.definition.name['en-US'],
      json: JSON.stringify(item, null, 2)
    });
    console.log(table);
  });

  console.log('TABLE 1', table);
  return table;
}

function getObjectiveNumber(data: string) {
  let x = parseInt(data.charAt(data.length - 1), 10);
  return x - 1;
}

const LessonDebrief: React.FC = () => {

  const tableRows = serialize(payload.statements);
  const tableRowsJson = serializeJsonTable(payload.statements);
  const tableRows1 = serialize1(payload.statements);

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [innerExpanded, setInnerExpanded] = React.useState<string | false>(false);

  const handleInnerChange = (innerPanel: string) => (event: React.SyntheticEvent, innerExpanded: boolean) => {
    setInnerExpanded(innerExpanded ? innerPanel : false);
  };
  return (
    <Box sx={{ marginTop: '53px' }}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <IconLessonList />
          <Typography sx={{
            fontSize: '20px',
            color: '#000000',
            fontWeight: 300,
            marginLeft: '22px',
          }}>
            Quiz
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
            <Box sx={{ displa: 'flex', flexDirection: 'column' }}>
              <Box sx={{ marginLeft: '30px' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '22px', lineHeight: '18px', marginBottom: '50px' }}>
                  Peripheral IV Cannulation Quiz
                </Typography>
              </Box>
              <Box sx={{ marginLeft: '30px', width: '300px'}}>
                <Typography sx={{ fontFamily: 'Lato', fontWeight: '300', fontSize: '15px', marginBottom: '20px' }}>
                  Complete this quiz after the simulations above are complete.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ marginLeft: '420px', display: 'flex', flexDirection: 'column' }}>
              {/* <a style={{ border: '1px solid #C4C4C4', textDecoration: 'none', borderRadius: '50px', marginBottom: '15px' }} href={require("../local-data/PeripheralIVCannulationQuiz4cad03a6-7e27-48db-89ec-e05894b8afa4.zip")} download="xAPI_File">
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '200', fontSize: '15px', color: 'black', padding: '3px 20px 3px 40px' }}>
                  Download as xAPI
                </Typography>
              </a> */}
              <a style={{ border: '1px solid #C4C4C4', textDecoration: 'none', borderRadius: '50px' }} href={require("../local-data/PeripheralIVCannulationQuiz4cad03a6-7e27-48db-89ec-e05894b8afa4.zip")} download="SCORMFile">
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '200', fontSize: '15px', color: 'black', padding: '3px 20px 3px 20px' }}>
                  Download as SCORM 1.2
                </Typography>
              </a>
            </Box>
          </Box>
          <Button variant="contained" color="primary" href='https://cloud.scorm.com/ScormEngineInterface/defaultui/player/modern.html?configuration=Nc6J9ESR4Gmf4MI1tf4gM5f-cy0OUkJ7ltCwG11cU7KxxcnDMKWjBXB0HY67efN_HmX85vYGO8X3M_WyprBs-6UqHPPr05gwgwamotna47FB9ljz7n2FTfns9GXVKx-eP0c4n3iq8Y0YMmNHyVUTbVIck8UrSQD9hh6kU5d6aMiQobgJZb6rJm9ml_tVYG3OxGRnD0vIDB2pwrZD351GSv8uBkNHrQ2F_ha-Jq_THYvBWNGpLCOwJZxaCAwUgLkgmJ-19bra4qaVFU1dF8uvduNlUW3xJUh9PL1rEpX2lHs5fhdC515TDzicRQzhA_qBElWJkcqBm5t5SPFIwDDz_m6OmF_K2h3bfSbYFGCjMjgugDUSg4O4_Ck6Ruoz0cgfAMVmQYRKnV38yGHVzAgUcohy4PcgiZEtyvt-PxlHD8E21A9ySDPqDFgJNN2s_6qqOY08ppt9mxRBAHKEUzRiLwMNwsnTo47DWW18UToJ5_Jy0VUfl_PJH2SLAI36y3EPZv9gn2zPGHWjkng3hdovyFSuhy0_Q6vVjuL50tz703FkCNCsXHVHyS95h6rfc7sYRg&preventRightClick=false&cc=pt_BR&cache=21.1.29.480&playerConfUrl=j&package=CBpYGcV9OjhopXYuVvRLy7IaMD5PSvaxTJck3TN6U2BFbyHMP3epB20dLgVPjqerKzhO3WYEPZ-fOykiejfcE9Bdn8kwN-uDQZvosGGiS0GKzkt6SuDgTJd6zzVnjeBnsBKZRdpOUZ9DkELPhkDRyYb0tw&redirectUrl=https%3A%2F%2Fapp.cloud.scorm.com%2Fsc%2Fguest%2FPostInvitation%3FuserInvitationId%3Dba1adf00-3a05-4c63-b8ed-aed2c3452ea6&registration=9C3Igntn8_YC1mK_JBhYmWhizOEzkV9i98C7kg2QHeAgHbrGeJGafZhgtvgJY0ydy3Szuc8NNdn6ka_t6y_QaftBOWBno8_84IXLaePptLl4wzHDiThyJCRgeVkQV18&tracking=true&forceReview=false&player=modern&ts=20230830154333' target="_blank"
            sx={{ textTransform: 'none', width: '160px', height: '48px', borderRadius: '0', marginLeft: '30px', marginBottom: '30px' }}>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontSize: '12px',
                fontWeight: '400'
              }}>
              Launch Quiz
            </Typography>
          </Button>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <IconLessonList />
          <Typography sx={{
            fontSize: '20px',
            color: '#000000',
            fontWeight: 300,
            marginLeft: '22px',
          }}>
            Performance Reports
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
          <Accordion expanded={innerExpanded === 'inner3'} onChange={handleInnerChange('inner3')} sx={{ borderBottom: 'none' }}>
            <AccordionSummary aria-controls="inner3-content" id="inner3-header" sx={{
              backgroundColor: '#EFF2F5',
              flexDirection: 'row',
              borderRadius: '70px',
              height: '70px',
              paddingLeft: '0px',
              paddingTop: '3px',
              marginTop: '0px',
              marginBottom: '0px'
            }}>
              <Box sx={{ marginRight: '80px', paddingTop: '6px' }}>
                <IconChatBox />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
                <Typography sx={{
                  width: '500px',
                  fontSize: '20px'
                }}>
                  Peripheral IV Patient Conversation
                </Typography>
                <Typography>
                  Started on Sept {day}, {year}
                </Typography>
              </Box>
              <Box sx={{ marginLeft: '180px' }}>
                Ended
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: '0px' }}>
              <Box sx={{ marginLeft: '30px', marginTop: '50px' }}>
                <Typography sx={{
                  fontWeight: 600,
                  fontSize: '18px',
                  color: '#000000',
                  fontFamily: 'Montserrat'
                }}>
                  Conversations &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; Peripheral IV Consent &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; Mon  Sept {day}, {year}
                </Typography>
              </Box>
              {/* <Box sx={{ marginLeft: '30px', marginTop: '50px', width: '680px' }}>
                <GridContainer container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1 }}>
                  <Grid item xs={1}>
                    <Item>
                      <BoxTitle>5</BoxTitle>
                      <BoxText>Students</BoxText>
                    </Item>
                  </Grid>
                  <Grid item xs={1}>
                    <Item>
                      <BoxTitle>1/1</BoxTitle>
                      <BoxText>Completed</BoxText>
                    </Item>
                  </Grid>
                  <Grid item xs={1}>
                    <Item>
                      <BoxTitle>21 min</BoxTitle>
                      <BoxText>Time in Session</BoxText>
                    </Item>
                  </Grid>
                  <Grid item xs={1}>
                    <Item>
                      <BoxTitle>100%</BoxTitle>
                      <BoxText>Participation Rate</BoxText>
                    </Item>
                  </Grid>
                </GridContainer>
              </Box> */}
              <Box sx={{ marginLeft: '30px', marginTop: '30px' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '22px', lineHeight: '18px', marginLeft: '10px', marginBottom: '20px' }}>
                  Session Details
                </Typography>
                {/* <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '19px', lineHeight: '18px', marginLeft: '10px', marginBottom: '5px' }}>
                    Session created:
                  </Typography>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '15px', lineHeight: '18px', marginLeft: '5px', marginBottom: '5px' }}>
                    Sept {day}, {year}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '19px', lineHeight: '18px', marginLeft: '10px', marginBottom: '5px' }}>
                    Session Started:
                  </Typography>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '15px', lineHeight: '18px', marginLeft: '5px', marginBottom: '5px' }}>
                    Sept {day}, {year}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '19px', lineHeight: '18px', marginLeft: '10px', marginBottom: '5px' }}>
                    Session Ended:
                  </Typography>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '15px', lineHeight: '18px', marginLeft: '5px', marginBottom: '5px' }}>
                    Sept {day}, {year}
                  </Typography>
                </Box> */}
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '19px', lineHeight: '18px', marginLeft: '10px', marginBottom: '5px' }}>
                    Users:
                  </Typography>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '15px', lineHeight: '18px', marginLeft: '5px', marginBottom: '5px' }}>
                    {names[1]}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ marginLeft: '30px', marginTop: '30px' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '22px', lineHeight: '18px', marginBottom: '20px' }}>
                  Objectives Summary Report
                </Typography>
              </Box>
              {/* TABLE */}
              <Box sx={{ marginLeft: '30px', marginTop: '15px' }}>
                <TableContainer container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0 }}>
                  {/* ROW 1 */}
                  <Grid item xs={1} md={3}>
                    <TableCell>
                      <ColumnTitle>Objectives</ColumnTitle>
                    </TableCell>
                  </Grid>
                  <Grid item xs={1} md={7}>
                    <TableCell>
                      <ColumnTitle>Description</ColumnTitle>
                    </TableCell>
                  </Grid>
                  <Grid item xs={1} md={2}>
                    <TableCell>
                      <ColumnTitle>Completed</ColumnTitle>
                    </TableCell>
                  </Grid>
                  {/* ROW 2 */}
                  <Grid item xs={2} md={3}>
                    <TableCell>
                      <CellText>1 Introduction</CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={7}>
                    <TableCell>
                      <CellText>Introduce yourself to the patient, including name and role.</CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <TableCell>
                      <CellText>1/1</CellText>
                    </TableCell>
                  </Grid>
                  {/* ROW 3 */}
                  <Grid item xs={2} md={3}>
                    <TableCell>
                      <CellText>2 Confirm patient</CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={7}>
                    <TableCell>
                      <CellText>Confirm patient’s name and date of birth.</CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <TableCell>
                      <CellText>1/1</CellText>
                    </TableCell>
                  </Grid>
                  {/* ROW 4 */}
                  <Grid item xs={2} md={3}>
                    <TableCell>
                      <CellText>3 Explain procedure</CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={7}>
                    <TableCell>
                      <CellText>Explain what the procedure will involve using patient-friendly language</CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <TableCell>
                      <CellText>1/1</CellText>
                    </TableCell>
                  </Grid>
                  {/* ROW 5 */}
                  <Grid item xs={2} md={3}>
                    <TableCell>
                      <CellText>4 Explain purpose</CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={7}>
                    <TableCell>
                      <CellText>Explain the purpose of the procedure.</CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <TableCell>
                      <CellText>1/1</CellText>
                    </TableCell>
                  </Grid>
                  {/* ROW 6 */}
                  <Grid item xs={2} md={3}>
                    <TableCell>
                      <CellText>5 Explain side effects</CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={7}>
                    <TableCell>
                      <CellText>Explain that cannulation can create short term discomfort.</CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <TableCell>
                      <CellText>1/1</CellText>
                    </TableCell>
                  </Grid>
                  {/* ROW 7 */}
                  <Grid item xs={2} md={3}>
                    <TableCell>
                      <CellText>6 Explain risks</CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={7}>
                    <TableCell>
                      <CellText>Explain the risk of the procedure.</CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <TableCell>
                      <CellText>1/1</CellText>
                    </TableCell>
                  </Grid>
                  {/* ROW 8 */}
                  <Grid item xs={2} md={3}>
                    <TableCell>
                      <CellText>7 Explain benefits</CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={7}>
                    <TableCell>
                      <CellText>Explain the benefits of the procedure.</CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <TableCell>
                      <CellText>1/1</CellText>
                    </TableCell>
                  </Grid>
                  {/* ROW 9 */}
                  <Grid item xs={2} md={3}>
                    <TableCell>
                      <CellText>8 Explain alternatives</CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={7}>
                    <TableCell>
                      <CellText>Explain the alternatives to the procedure.</CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <TableCell>
                      <CellText>1/1</CellText>
                    </TableCell>
                  </Grid>
                  {/* ROW 10 */}
                  <Grid item xs={2} md={3}>
                    <TableCell>
                      <CellText>9 Confirm understanding</CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={7}>
                    <TableCell sx={{ paddingTop: '5px' }}>
                      <CellText>Confirm the patient’s understanding of the process, risks and benefits of the procedure.</CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <TableCell>
                      <CellText>1/1</CellText>
                    </TableCell>
                  </Grid>
                  {/* ROW 11 */}
                  <Grid item xs={2} md={3}>
                    <TableCell>
                      <CellText>10 Obtain consent</CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={7}>
                    <TableCell>
                      <CellText>Obtain consent from the patient to proceed.</CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <TableCell>
                      <CellText>1/1</CellText>
                    </TableCell>
                  </Grid>
                </TableContainer>
              </Box>
              <Box sx={{ marginLeft: '30px', marginTop: '50px' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '22px', lineHeight: '18px', marginBottom: '20px' }}>
                  User Objectives Report
                </Typography>
              </Box>
              {/* TABLE */}
              <Box sx={{ marginLeft: '30px', marginTop: '15px' }}>
                <TableContainer container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0 }}>
                  {/* ROW 1 */}
                  <Grid item xs={1} md={3}>
                    <TableCell>
                      <ColumnTitle>Name</ColumnTitle>
                    </TableCell>
                  </Grid>
                  <Grid item xs={1} md={1.5}>
                    <TableCell sx={{ paddingLeft: '20px' }}>
                      <ColumnTitle sx={{ fontSize: '14px' }}>Objective 1</ColumnTitle>
                    </TableCell>
                  </Grid>
                  <Grid item xs={1} md={1.5}>
                    <TableCell sx={{ paddingLeft: '20px' }}>
                      <ColumnTitle sx={{ fontSize: '14px' }}>Objective 2</ColumnTitle>
                    </TableCell>
                  </Grid>
                  <Grid item xs={1} md={1.5}>
                    <TableCell sx={{ paddingLeft: '20px' }}>
                      <ColumnTitle sx={{ fontSize: '14px' }}>Objective 3</ColumnTitle>
                    </TableCell>
                  </Grid>
                  <Grid item xs={1} md={1.5}>
                    <TableCell sx={{ paddingLeft: '20px' }}>
                      <ColumnTitle sx={{ fontSize: '14px' }}>Objective 4</ColumnTitle>
                    </TableCell>
                  </Grid>
                  <Grid item xs={1} md={1.5}>
                    <TableCell sx={{ paddingLeft: '20px' }}>
                      <ColumnTitle sx={{ fontSize: '14px' }}>Objective 5</ColumnTitle>
                    </TableCell>
                  </Grid>
                  <Grid item xs={1} md={1.5}>
                    <TableCell sx={{ paddingLeft: '20px' }}>
                      <ColumnTitle sx={{ fontSize: '14px' }}>Objective 6</ColumnTitle>
                    </TableCell>
                  </Grid>
                  {/* ROW 2 */}

                  {/* ROW 3 */}

                  {/* ROW 4 */}

                  {/* ROW 5 */}
                  <Grid item xs={2} md={3}>
                    <TableCell>
                      <CellText>Stephen McIver</CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={1.5}>
                    <TableCell>
                      <CellText sx={{ paddingLeft: '20px' }}>
                        <CheckCircleIcon sx={{ color: '#62BEFC' }} />
                      </CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={1.5}>
                    <TableCell>
                      <CellText sx={{ paddingLeft: '20px' }}>
                        <CheckCircleIcon sx={{ color: '#62BEFC' }} />
                      </CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={1.5}>
                    <TableCell>
                      <CellText sx={{ paddingLeft: '20px' }}>
                        <CheckCircleIcon sx={{ color: '#62BEFC' }} />
                      </CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={1.5}>
                    <TableCell>
                      <CellText sx={{ paddingLeft: '20px' }}>
                        <CheckCircleIcon sx={{ color: '#62BEFC' }} />
                      </CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={1.5}>
                    <TableCell>
                      <CellText sx={{ paddingLeft: '20px' }}>
                        <CheckCircleIcon sx={{ color: '#62BEFC' }} />
                      </CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={1.5}>
                    <TableCell>
                      <CellText sx={{ paddingLeft: '20px' }}>
                        <CheckCircleIcon sx={{ color: '#62BEFC' }} />
                      </CellText>
                    </TableCell>
                  </Grid>
                  {/* ROW 6 */}

                </TableContainer>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={innerExpanded === 'inner2'} onChange={handleInnerChange('inner2')} sx={{ borderBottom: 'none' }}>
            <AccordionSummary aria-controls="inner2-content" id="inner2-header" sx={{
              backgroundColor: '#EFF2F5',
              flexDirection: 'row',
              borderRadius: '70px',
              height: '70px',
              paddingLeft: '0px',
              paddingTop: '3px',
              marginTop: '0px',
              marginBottom: '0px'
            }}>
              <Box sx={{ marginRight: '80px', paddingTop: '6px' }}>
                <IconLessonGlasses />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
                <Typography sx={{
                  width: '230px',
                  fontSize: '20px'
                }}>
                  Peripheral Vein Anatomy
                </Typography>
                <Typography>
                  Started on Sept {day}, {year}
                </Typography>
              </Box>
              <Box sx={{ marginLeft: '180px' }}>
                Ended
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: '0px' }}>
              <Box sx={{ marginLeft: '30px', marginTop: '50px' }}>
                <Typography sx={{
                  fontWeight: 600,
                  fontSize: '18px',
                  color: '#000000',
                  fontFamily: 'Montserrat'
                }}>
                  HoloHuman &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; Peripheral Vein Anatomy Session &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; Mon  Sept {day}, {year}
                </Typography>
              </Box>
              {/* <Box sx={{ marginLeft: '30px', marginTop: '50px', width: '680px' }}>
                <GridContainer container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1 }}>
                  <Grid item xs={1}>
                    <Item>
                      <BoxTitle>5</BoxTitle>
                      <BoxText>Students</BoxText>
                    </Item>
                  </Grid>
                  <Grid item xs={1}>
                    <Item>
                      <BoxTitle>1/1</BoxTitle>
                      <BoxText>Completed</BoxText>
                    </Item>
                  </Grid>
                  <Grid item xs={1}>
                    <Item>
                      <BoxTitle>30 min</BoxTitle>
                      <BoxText>Time in Session</BoxText>
                    </Item>
                  </Grid>
                  <Grid item xs={1}>
                    <Item>
                      <BoxTitle>100%</BoxTitle>
                      <BoxText>Participation Rate</BoxText>
                    </Item>
                  </Grid>
                </GridContainer>
              </Box> */}
              <Box sx={{ marginLeft: '30px', marginTop: '30px' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '22px', lineHeight: '18px', marginLeft: '10px', marginBottom: '20px' }}>
                  Session Details
                </Typography>
                {/* <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '19px', lineHeight: '18px', marginLeft: '10px', marginBottom: '5px' }}>
                    Session created:
                  </Typography>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '15px', lineHeight: '18px', marginLeft: '5px', marginBottom: '5px' }}>
                    Sept {day}, {year}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '19px', lineHeight: '18px', marginLeft: '10px', marginBottom: '5px' }}>
                    Session Started:
                  </Typography>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '15px', lineHeight: '18px', marginLeft: '5px', marginBottom: '5px' }}>
                    Sept {day}, {year}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '19px', lineHeight: '18px', marginLeft: '10px', marginBottom: '5px' }}>
                    Session Ended:
                  </Typography>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '15px', lineHeight: '18px', marginLeft: '5px', marginBottom: '5px' }}>
                    Sept {day}, {year}
                  </Typography>
                </Box> */}
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '19px', lineHeight: '18px', marginLeft: '10px', marginBottom: '5px' }}>
                    Users:
                  </Typography>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '15px', lineHeight: '18px', marginLeft: '5px', marginBottom: '5px' }}>
                    {names[0]}, {names[1]}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ marginLeft: '30px', marginTop: '30px' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '22px', lineHeight: '18px', marginBottom: '20px' }}>
                  Objectives Summary Report
                </Typography>
              </Box>
              <ObjectivesTable1 tableRows={ObjectivesData} />
              <Box sx={{ marginLeft: '30px', marginTop: '50px' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '22px', lineHeight: '18px', marginBottom: '20px' }}>
                  User Objectives Report
                </Typography>
              </Box>
              {/* TABLE */}
              <Box sx={{ marginLeft: '30px', marginTop: '15px' }}>
                <TableContainer container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0 }}>
                  {/* ROW 1 */}
                  <Grid item xs={1} md={3}>
                    <TableCell>
                      <ColumnTitle>Name</ColumnTitle>
                    </TableCell>
                  </Grid>
                  <Grid item xs={1} md={2}>
                    <TableCell>
                      <ColumnTitle sx={{ fontSize: '14px' }}>Objective 1</ColumnTitle>
                    </TableCell>
                  </Grid>
                  <Grid item xs={1} md={2}>
                    <TableCell>
                      <ColumnTitle sx={{ fontSize: '14px' }}>Objective 2</ColumnTitle>
                    </TableCell>
                  </Grid>
                  <Grid item xs={1} md={5}>
                    <TableCell>
                      <ColumnTitle sx={{ fontSize: '14px', marginLeft: '145px' }}>Completed</ColumnTitle>
                    </TableCell>
                  </Grid>
                  {/* ROW 2 */}
                  <Grid item xs={2} md={3}>
                    <TableCell>
                      <CellText>Stephen McIver</CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <TableCell>
                      <CellText sx={{ paddingLeft: '40px' }}>
                        <CheckCircleIcon sx={{ color: '#62BEFC' }} />
                      </CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <TableCell>
                      <CellText sx={{ paddingLeft: '40px' }}>
                        <CheckCircleIcon sx={{ color: '#62BEFC' }} />
                      </CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={5}>
                    <TableCell>
                      <CellText sx={{ marginLeft: '170px' }}>
                      <CheckCircleIcon sx={{ color: '#62BEFC' }} />
                      </CellText>
                    </TableCell>
                  </Grid>
                  {/* ROW 3 */}
                  <Grid item xs={2} md={3}>
                    <TableCell>
                      <CellText>Chris Hartman</CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <TableCell>
                      <CellText sx={{ paddingLeft: '40px' }}>
                        <CheckCircleIcon sx={{ color: '#62BEFC' }} />
                      </CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <TableCell>
                      <CellText sx={{ paddingLeft: '40px' }}>
                      </CellText>
                    </TableCell>
                  </Grid>
                  <Grid item xs={2} md={5}>
                    <TableCell>
                      <CellText>
                      </CellText>
                    </TableCell>
                  </Grid>
                  {/* ROW 4 */}

                  {/* ROW 5 */}

                  {/* ROW 6 */}

                </TableContainer>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={innerExpanded === 'inner1'} onChange={handleInnerChange('inner1')} sx={{ borderBottom: 'none' }}>
            <AccordionSummary aria-controls="inner1-content" id="inner1-header" sx={{
              backgroundColor: '#EFF2F5',
              flexDirection: 'row',
              borderRadius: '70px',
              height: '70px',
              paddingLeft: '0px',
              paddingTop: '3px',
              marginTop: '0px',
              marginBottom: '0px'
            }}>
              <Box sx={{ marginRight: '80px', paddingTop: '6px' }}>
                <IconLessonGlasses />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
                <Typography sx={{
                  width: '230px',
                  fontSize: '20px'
                }}>
                  Peripheral IV Cannulation
                </Typography>
                <Typography>
                  Started on Sept {day}, {year}
                </Typography>
              </Box>
              <Box sx={{ marginLeft: '180px' }}>
                Ended
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: '0px' }}>
              <Box sx={{ marginLeft: '30px', marginTop: '50px' }}>
                <Typography sx={{
                  fontWeight: 600,
                  fontSize: '18px',
                  color: '#000000',
                  fontFamily: 'Montserrat'
                }}>
                  HoloProcedures &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; Peripheral IV Cannulation Session &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; Mon  Sept {day}, {year}
                </Typography>
              </Box>
              {/* <Box sx={{ marginLeft: '30px', marginTop: '50px', width: '680px' }}>
                <GridContainer container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1 }}>
                  <Grid item xs={1}>
                    <Item>
                      <BoxTitle>5</BoxTitle>
                      <BoxText>Students</BoxText>
                    </Item>
                  </Grid>
                  <Grid item xs={1}>
                    <Item>
                      <BoxTitle>3/5</BoxTitle>
                      <BoxText>Completed</BoxText>
                    </Item>
                  </Grid>
                  <Grid item xs={1}>
                    <Item>
                      <BoxTitle>45 min</BoxTitle>
                      <BoxText>Time in Session</BoxText>
                    </Item>
                  </Grid>
                  <Grid item xs={1}>
                    <Item>
                      <BoxTitle>80%</BoxTitle>
                      <BoxText>Participation Rate</BoxText>
                    </Item>
                  </Grid>
                </GridContainer>
              </Box> */}
              <Box sx={{ marginLeft: '30px', marginTop: '30px' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '22px', lineHeight: '18px', marginLeft: '10px', marginBottom: '20px' }}>
                  Session Details
                </Typography>
                {/* <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '19px', lineHeight: '18px', marginLeft: '10px', marginBottom: '5px' }}>
                    Session created:
                  </Typography>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '15px', lineHeight: '18px', marginLeft: '5px', marginBottom: '5px' }}>
                    Sept {day}, {year}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '19px', lineHeight: '18px', marginLeft: '10px', marginBottom: '5px' }}>
                    Session Started:
                  </Typography>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '15px', lineHeight: '18px', marginLeft: '5px', marginBottom: '5px' }}>
                    Sept {day}, {year}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '19px', lineHeight: '18px', marginLeft: '10px', marginBottom: '5px' }}>
                    Session Ended:
                  </Typography>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '15px', lineHeight: '18px', marginLeft: '5px', marginBottom: '5px' }}>
                    Sept {day}, {year}
                  </Typography>
                </Box> */}
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '19px', lineHeight: '18px', marginLeft: '10px', marginBottom: '5px' }}>
                    Users:
                  </Typography>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '15px', lineHeight: '18px', marginLeft: '5px', marginBottom: '5px' }}>
                    {names[0]}, {names[1]}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ marginLeft: '30px', marginTop: '30px' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '22px', lineHeight: '18px', marginBottom: '20px' }}>
                  Procedure Report: Holographic PIVC
                </Typography>
              </Box>
              <ObjectivesTable tableRows={holographicPIVCObjectices} />
              <Box sx={{ marginLeft: '30px', marginTop: '50px' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '22px', lineHeight: '18px', marginBottom: '20px' }}>
                  User Progress
                </Typography>
              </Box>
              <ProgressTable tableRows={tableRows} />
              <Box sx={{ marginLeft: '30px', marginTop: '100px' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '22px', lineHeight: '18px', marginBottom: '20px' }}>
                  Procedure Report: Task Trainer PIVC
                </Typography>
              </Box>
              <ObjectivesTable tableRows={TaskTrainerPIVCObjectives} />
              <Box sx={{ marginLeft: '30px', marginTop: '50px' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '22px', lineHeight: '18px', marginBottom: '20px' }}>
                  User Progress
                </Typography>
              </Box>
              <ProgressTable1 tableRows={tableRows1} />
              <Box sx={{ marginLeft: '30px', marginTop: '50px' }}>
              <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '22px', lineHeight: '18px', marginBottom: '20px' }}>
                Session History
              </Typography>
              </Box>
              <Box sx={{ marginLeft: '30px' }}>
              <TestTable data={tableRowsJson}/>
              </Box>
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default LessonDebrief;
