import * as React from 'react';
import Typography from '@mui/material/Typography';
import AvatarG from '../img/avatar-g.png';
import Box from '@mui/material/Box';

const AuthorCard: React.FC = ({ lessonTitle }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '16px' }}>
      <Box sx={{ width: '44px', height: '44px', marginRight: '27px' }}>
        <img src={AvatarG} />
      </Box>
      <Box sx={{ fontWeight: 500 }}>
        <Typography sx={{ color: '#4A4A4A' }}>{lessonTitle}</Typography>
      </Box>
    </Box>
  );
};

export default AuthorCard;
