import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import QRCode from 'react-qr-code';
import Moment from 'react-moment';
import { useContext, useEffect, useState } from 'react';
import {
  REQ_STATUS_IDLE,
  REQ_STATUS_PENDING,
  REQ_STATUS_REJECTED,
  REQ_STATUS_RESOLVED,
  RequestStatusType,
} from '../helpers/types';
import { CircularProgress } from '@mui/material';
import { AppContext } from './AppContext';

const ButtonGenerate = styled(Button)(({ theme }) => ({
  ...theme.typography.caption,
  backgroundColor: '#FFFFFF',
  borderColor: '#C4C4C4',
  borderRadius: '20px',
  paddingX: '15px',
  paddingY: '10px',
  color: '#000000',
  textTransform: 'none',
  letterSpacing: '0.01em',
  alignSelf: 'center',
  '&.MuiButtonBase-root:hover': {
    backgroundColor: '#FFFFFF',
    borderColor: '#C4C4C4',
  },
}));

type QRCodeType = {
  qrCode: string;
};
type SessionDetailsType = {
  sessionName: string;
  lessonDate: Date;
};
const QRCodeGenerate: React.FC = (props: any) => {
  const appContext = useContext(AppContext);
  const [qrData, setQrData] = useState<QRCodeType | null>(null);
  const [reqStatus, setReqStatus] = useState<RequestStatusType>(REQ_STATUS_IDLE);
  const [authJWT, setAuthJWT] = useState<string>('');
  const [session, setSession] = useState<SessionDetailsType | null>(null);
  const [sessionId, setSessionId] = useState<string>('');

  useEffect(() => {
    if (sessionId !== '') {
      getSessionDetails(sessionId, props.sessionName);
    }
  }, [sessionId]);

  useEffect(() => {
    getSessionId();
    if (appContext.jwt !== '') {
      setAuthJWT(appContext.jwt);
    }

    return () => {
      setQrData(null);
      setAuthJWT('');
    };
  }, []);

  const generateQr = async () => {
    setReqStatus(REQ_STATUS_PENDING);
    try {
      const response = await fetch(`https://gms-qa.gigxr.com/api/v1/accounts/qr`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-auth-gms': 'Bearer ' + authJWT,
        },
        body: JSON.stringify({
          callbackPayload: `JOIN SESSION ${sessionId}`,
        }),
      });
      const result = await response.json();
      if (result.data !== undefined) {
        const data: QRCodeType = {
          qrCode: result.data.qrCode.toString(),
        };
        setQrData(data);
      }
      setReqStatus(REQ_STATUS_RESOLVED);
    } catch (error) {
      setReqStatus(REQ_STATUS_REJECTED);
    }
  };

  const getSessionDetails = async (id: string, sessionName: string) => {
    try {
      const response = await fetch(`https://gms-qa.gigxr.com/api/v1/sessions/${id}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-auth-gms': 'Bearer ' + authJWT,
        },
      });
      const result = await response.json();
      if (result.data !== undefined) {
        const data: SessionDetailsType = {
          sessionName: sessionName,
          lessonDate: result.data.lessonDate,
        };
        setSession(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSessionId = async () => {
    try {
      const response = await fetch(`https://va.gigxr.com/session-id.txt?cache=${new Date()}`);
      const result = await response.text();
      if (result.toString() !== '') {
        setSessionId(result.toString().trim());
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        width: '442px',
        backgroundColor: '#EFF2F5',
        borderRadius: '10px',
        display: 'flex',
        paddingX: '30px',
        paddingY: '40px',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <>
        <ButtonGenerate onClick={() => generateQr()} disableElevation={true} variant="outlined" color="secondary">
          Generate QR Code
        </ButtonGenerate>

        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '33px' }}>
          <Box sx={{ width: '112px' }}>
            {reqStatus === REQ_STATUS_PENDING ? (
              <CircularProgress color="secondary" />
            ) : (
              qrData !== null && (
                <QRCode size={112} style={{ height: 'auto' }} bgColor="#ffffff" value={qrData.qrCode} />
              )
            )}
          </Box>
          <Box sx={{ marginLeft: '53px' }}>
            <Typography sx={{ fontWeight: 400 }}>Upcoming Session</Typography>
            {session !== null && (
              <Typography sx={{ fontWeight: 300, fontSize: '15px' }}>{session.sessionName}</Typography>
            )}
          </Box>
        </Box>
      </>
    </Box>
  );
};

export default QRCodeGenerate;
