import React, { useContext, useEffect, useState } from 'react';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import {
  JWTLoginDecodeType,
  REQ_STATUS_IDLE,
  REQ_STATUS_PENDING,
  REQ_STATUS_REJECTED,
  REQ_STATUS_RESOLVED,
  RequestStatusType,
  UserType,
} from '../helpers/types';
import { SignInType } from '../pages/SignIn';
import { AppContext } from '../components/AppContext';

export type LoginType = {
  email: string;
  password: string;
  clientAppId: string;
  validDuration?: string;
};
type SecurityType = {
  reqStatus: RequestStatusType;
  signIn: (d: SignInType) => void;
  signOut: () => void;
  user: UserType | null;
};

export const useAuthentication = () => {
  const appContext = useContext(AppContext);
  const [reqStatus, setReqStatus] = useState<RequestStatusType>(REQ_STATUS_IDLE);
  const [user, setUser] = useState<UserType | null>(null);

  const signIn = async (data: SignInType) => {
    const reqData: LoginType = {
      clientAppId: '5516eb34-611a-4eac-b2d6-834a8c1d4509',
      ...data,
    };

    setReqStatus(REQ_STATUS_PENDING);
    try {
      const response = await fetch(`https://gms-qa.gigxr.com/api/v1/accounts/login`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqData),
      });
      const result = await response.json();
      if (result.data !== undefined && result.data.jsonWebToken !== undefined) {
        appContext.setJwt(result.data.jsonWebToken);
        const decoded = jwtDecode<JWTLoginDecodeType>(result.data.jsonWebToken);
        const loggedUsed: UserType = {
          accountId: decoded.nameid,
          firstName: decoded.firstName,
          lastName: decoded.lastName,
          email: decoded.unique_name,
          accountRole: decoded.role,
        };
        setUser(loggedUsed);
      }
      setReqStatus(REQ_STATUS_RESOLVED);
    } catch (error) {
      setReqStatus(REQ_STATUS_REJECTED);
    }
  };
  const signOut = async () => {
    //TODO need to add header x-auth-gms: Bearer jsonWebToken

    setReqStatus(REQ_STATUS_PENDING);
    try {
      const response = await fetch(`https://gms-qa.gigxr.com/api/v1/accounts/logout`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: '',
      });
      const result = await response.json();
      setUser(null);
      setReqStatus(REQ_STATUS_RESOLVED);
    } catch (error) {
      setReqStatus(REQ_STATUS_REJECTED);
    }
  };
  const auth: SecurityType = {
    reqStatus,
    user,
    signIn,
    signOut,
  };
  return { auth };
};
