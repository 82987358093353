import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as IconDashboard } from '../img/icon-bricks.svg';
import { ReactComponent as IconClipboard } from '../img/icon-clipboard.svg';
import { ReactComponent as IconDisk } from '../img/icon-disk.svg';
import { ReactComponent as IconLogout } from '../img/icon-logout.svg';
import { ReactComponent as IconQuestionMark } from '../img/icon-question-mark.svg';
import { ReactComponent as IconTarget } from '../img/icon-target.svg';
import { ReactComponent as IconUserWhite } from '../img/icon-user-white.svg';
import { ReactComponent as IconHamburger } from '../img/icon-hamburger.svg';

type PropType = {
  handleToggleDrawer: () => void;
};

const SidebarMenu: React.FC<PropType> = ({ handleToggleDrawer }) => {
  return (
    <>
      <ListItemButton onClick={handleToggleDrawer} sx={{ mb: 10 }}>
        <ListItemIcon sx={{ marginLeft: '5px' }}>
          <IconHamburger />
        </ListItemIcon>
      </ListItemButton>
      <ListItemButton to={'/app/'} component={RouterLink}>
        <ListItemIcon sx={{ marginLeft: '5px' }}>
          <IconDashboard />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      {/*<ListItemButton>*/}
      {/*  <ListItemIcon>*/}
      {/*    <IconDisk />*/}
      {/*  </ListItemIcon>*/}
      {/*  <ListItemText primary="Saved Sessions" />*/}
      {/*</ListItemButton>*/}
      {/*<ListItemButton>*/}
      {/*  <ListItemIcon>*/}
      {/*    <IconClipboard />*/}
      {/*  </ListItemIcon>*/}
      {/*  <ListItemText primary="Session Plans" />*/}
      {/*</ListItemButton>*/}
      {/*<ListItemButton>*/}
      {/*  <ListItemIcon>*/}
      {/*    <IconTarget />*/}
      {/*  </ListItemIcon>*/}
      {/*  <ListItemText primary="Calibrate Room" />*/}
      {/*</ListItemButton>*/}
      {/*<ListItemButton>*/}
      {/*  <ListItemIcon>*/}
      {/*    <IconUserWhite />*/}
      {/*  </ListItemIcon>*/}
      {/*  <ListItemText primary="My Account" />*/}
      {/*</ListItemButton>*/}
      <ListItemButton to={'/app/chat'} component={RouterLink}>
        <ListItemIcon sx={{ marginLeft: '5px' }}>
          <IconQuestionMark />
        </ListItemIcon>
        <ListItemText primary="Chat Support" />
      </ListItemButton>
      <ListItemButton to={'/'} component={RouterLink}>
        <ListItemIcon sx={{ marginLeft: '7px' }}>
          <IconLogout />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </>
  );
};

export default SidebarMenu;
