import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function Row(data: {data: {actor: string, verb: string, objectName: string, json: string}}) {
  const [open, setOpen] = React.useState(false);
  console.log(data)
  return (
    <React.Fragment>
      <TableRow>
        <TableCell sx={{ borderBottom: "1px solid #62BEFC" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" sx={{ borderBottom: "1px solid #62BEFC", }}>
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '15px', display: 'flex', flexDirection: 'row' }}>
            {data.data.actor}
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '15px', color: '#62BEFC', fontWeight: 700 }}>
              &nbsp; {data.data.verb} &nbsp;
            </Typography>
            {data.data.objectName}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, height: '500px', overflow: 'auto'}}>
              <Table>
                <TableBody>
                      <TableCell component="th" scope="row" sx={{ whiteSpace: 'pre-line', backgroundColor: '#F5F5F5'}}>
                        {data.data.json}
                      </TableCell>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const json = `{
  "id": "85c5d8ae-85fe-402d-8a03-7543c520b713",
  "actor": {
  "name": "Chris Hartman",
  "objectType": "Agent",
  "mbox": "mailto:chris.hartman@gigxr.com"
  },
  "verb": {
  "id": "https://va.gigxr.gov/xapi/verbs/ended",
  "display": {
  "en-US": "Ended"
  }
  },
  "context": {
  "instructor": {
  "name": "Chris Hartman",
  "objectType": "Agent",
  "mbox": "mailto:chris.hartman@gigxr.com"
  },
  "contextActivities": {
  "parent": [`

export default function TestTable(data: {data: [{actor: string, verb: string, objectName: string, json: string}]}) {
  console.log(data)
  return (
    <TableContainer component={Paper} sx={{ borderRadius: '1px' }}>
      <Table aria-label="collapsible table" sx={{ borderTop: "1px solid #62BEFC", borderBottom: "1px solid #62BEFC", borderRight: "1px solid #62BEFC", borderLeft: "1px solid #62BEFC"}}>
        <TableBody>
          {data.data.map((item) => (
            <Row data={item}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}