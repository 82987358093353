import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import AuthorCard from './AuthorCard';
import { LessonListType } from '../helpers/types';
import { styled } from '@mui/material/styles';

const HeadImg = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  color: '#929292',
  lineHeight: '20px',
  marginBottom: '10px',
}));

const RowLesson: React.FC<LessonListType> = ({ data, children }) => {
  return (
    <Grid item xs={12} sx={{ marginBottom: '80px' }}>
      {data.starting !== undefined && <HeadImg>Starting: {data.starting}</HeadImg>}
      <Card sx={{ display: 'flex', borderRadius: 'initial', boxShadow: 'unset' }}>
        <CardMedia
          component="img"
          sx={{ width: 528, height: 248, display: { xs: 'none', sm: 'block' } }}
          image={data.image}
          alt="About Lesson"
        />
        <CardContent
          sx={{
            flex: 1,
            marginLeft: '63px',
            p: '0 !important',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography
            component="h2"
            sx={{
              fontWeight: 500,
              fontSize: '24px',
              letterSpacing: '0.1em',
              color: '#000000',
            }}
          >
            About Lesson
          </Typography>
          <Typography sx={{ fontWeight: 300, letterSpacing: '0.1em', color: '#000000', my: '30px' }}>
            {data.summary}
          </Typography>
          {children}
        </CardContent>
      </Card>
      <AuthorCard lessonTitle={data.title} />
    </Grid>
  );
};

export default RowLesson;
